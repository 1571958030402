import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🤝-los-protocolos-de-plastilina",
      "style": {
        "position": "relative"
      }
    }}>{`🤝 Los protocolos de plastilina`}<a parentName="h1" {...{
        "href": "#%F0%9F%A4%9D-los-protocolos-de-plastilina",
        "aria-label": "🤝 los protocolos de plastilina permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Sellando el acuerdo`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod1-img/indusseals.gif",
            "alt": "Seals and Sealing"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://nakamotoinstitute.org/the-playdough-protocols/" mdxType="Button">
    Leer
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <p>{`El ensayo anterior trata sobre la historia de los sellos y cómo, utilizando el equivalente digital de los sellos,
podemos brindar integridad de datos e identidades infalsificables al comercio en línea. Escrito por Nick Szabo
en 2002, proporciona una ventana única para comprender por qué blockchains son importantes más allá
de las aplicaciones puramente monetarias.`}</p>
    <h2 {...{
      "id": "¿cómo-encaja-en-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`¿Cómo encaja en Kernel?`}<a parentName="h2" {...{
        "href": "#%C2%BFc%C3%B3mo-encaja-en-kernel",
        "aria-label": "¿cómo encaja en kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Hemos elegido este ensayo específicamente porque muestra cómo el dinero y el comercio condujeron
al desarrollo del lenguaje escrito; una idea que comenzamos a explorar con
`}<a parentName="p" {...{
        "href": "/learn/module-0/money-language"
      }}>{`Andreas Antonopoulos`}</a>{` en el módulo anterior. Además,
habla directamente de:`}</p>
    <Process mdxType="Process">
      <p>{`¿Por qué deberíamos (continuar) construyendo herramientas resistentes a la censura?`}</p>
      <p>{`Cómo siempre hemos asegurado, y podemos seguir haciéndolo, nuestras comunicaciones más valiosas.`}</p>
    </Process>
    <h2 {...{
      "id": "resumen",
      "style": {
        "position": "relative"
      }
    }}>{`Resumen`}<a parentName="h2" {...{
        "href": "#resumen",
        "aria-label": "resumen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`La integridad de los datos es `}<strong parentName="p">{`crítica`}</strong>{` para las relaciones comerciales y de confianza. Sin ninguna forma de garantizar
la calidad y procedencia de los bienes, no puede ocurrir ningún comercio significativo. De hecho, fue esta necesidad la que condujo casi directamente al desarrollo de los primeros símbolos escritos. Para comprender esta historia, debemos investigar cómo se usaron tales símbolos, tallados en arcilla y sellos de piedra, para garantizar la integridad.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Vamos a distinguir entre sellos, los cilindros o sellos a menudo hábilmente tallados que hacen las
impresiones, y los sellos, las impresiones resultantes laminadas o estampadas en arcilla húmeda, y la arcilla
sobre la que se imprimieron. Los sellos de arcilla se envolvieron alrededor de nudos de cuerda para sellar fardos de mercancías,
y alrededor de los bordes de cestas de mimbre y jarras de cerámica para sellar su contenido `}{`[...]`}{` Los sellos se
tallaban en materiales duros, generalmente piedra, pero a veces loza, vidrio, metal, madera o hueso.
A veces se usaba arcilla seca o cocida. Los griegos y los romanos usaban anillos de sello, sus
extremos de acción en forma de metal o tallados en gemas, para estampar la cera."`}</p>
    </blockquote>
    <h3 {...{
      "id": "fe-y-finanzas",
      "style": {
        "position": "relative"
      }
    }}>{`Fe y Finanzas`}<a parentName="h3" {...{
        "href": "#fe-y-finanzas",
        "aria-label": "fe y finanzas permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Los sellos de cuño más antiguos que se encontraron fueron los que se usaban en Irán en el año 5000 a. C. Más tarde, los arqueólogos utilizan
tanto el comercio de sellos en sí, como las distancias entre los sellos y los estanpados correspondientes, para rastrear redes de comercio a larga distancia".`}</p>
    </blockquote>
    <p>{`Es un punto crítico y poco valorado: `}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Los medios para asegurar nuestras relaciones comerciales y, por lo tanto, de confianza, son también los medios por los cuales podemos entender la red dentro de la cual operan.  `}</p>
    </blockquote>
    <p>{`Esto sigue siendo cierto hoy en día, y proporciona un contexto claro de por qué un medio compartido, global, sin propietario y sin fronteras para registrar la historia es un desarrollo tan vital e interesante.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Para los sumerios, un protocolo comercial también era un ritual religioso, y al revés también solía
ser cierto. En el Oriente Medio, la ruptura del sello se convirtió en uno de los más importantes rituales,
con consecuencias espirituales terribles si el sello era roto por la persona equivocada o en el momento equivocado".`}</p>
    </blockquote>
    <p>{`De algunas formas importantes oscurecidas en nuestra era secular, las finanzas siempre han tenido que ver con la fe. Tanto las instituciones religiosas y los rituales (como la ruptura de sellos) establecieron redes comerciales mucho más amplias, y en el sentido más simple establecieron el concepto que cualquier instrumento financiero fundamentalmente tiene que ver con la confianza (ya sea en una persona o en algún evento futuro). La próxima semana, invitaremos nuevamente a Nick a hablar sobre el dinero como representación de altruismo recíproco futuro, pero es mucho más simple y directo señalar que `}<strong parentName="p">{`el dinero es una muestra de fe`}</strong>{`. Lo que crees informa a lo que prestas atención y cómo actúas, lo que define a qué le otorgas valor. Como el valor se genera a partir de la confianza en verdades claramente compartidas, cuanto más efectivas sean tus creencias para lograr que otros se comporten de manera similar, más el valor finalmente se acumulará para ellos.`}</p>
    <p>{`Aquí está el corazón de lo que Kernel está tratando de enseñarte. Las historias que te cuentas a ti mismo sobre quién eres y cómo llegaste a encontrarte en este espacio y tiempo único, informan fundamentalmente todo lo que aprenderás sobre la confianza, el dinero, el discurso, la libertad y todas las demás palabras que inevitablemente escucharás cuando se habla de blockchain.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: Redención, ajuste de cuentas, reconciliación, contrato, vínculo y tantas otras palabras prueban las raíces compartidas de la fe y las finanzas. En este sentido, podemos decir que el dinero es un `}{`_`}{`_`}{`_`}{`_`}{`_`}{` de `}{`_`}{`_`}{`_`}{`_`}{`_`}{`.`}</p>
        <p>{`R: token, fe.`}</p>
      </Card>
    </Flash>
    <p>{`Así que sigue leyendo, porque hemos llegado a la parte donde el comercio y el dinero dan al desarrollo de la escritura:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Los primeros documentos jamás escritos, en el cuarto milenio a. C., trataban sobre el trigo y la cebada,
y estaban sellados. Mucho antes aún, al menos en el año 8.000 a. C., los arqueólogos han encontrado
artefactos aún más destacados: una gran cantidad de pequeñoss tokens de arcillas `}{`[...]`}{` Ahora sabemos que estos
tokens condujeron directamente a lo que son los fundamentos de nuestra civilización: lectura, escritura y aritmética."`}</p>
    </blockquote>
    <h3 {...{
      "id": "historia-de-la-escritura",
      "style": {
        "position": "relative"
      }
    }}>{`Historia de la escritura`}<a parentName="h3" {...{
        "href": "#historia-de-la-escritura",
        "aria-label": "historia de la escritura permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Poco después del año 4000 a. C., los tokens de arcilla se asociaban con la idea de sellar para crear
conocimientos de embarque y recibos de almacén. Para crear un conocimiento de embarque para un envío de ovejas,
el propietario colocaba un token de una oveja por cada oveja. Cada vez que contaba cinco ovejas, un token de cinco ovejas
podía ser sustituida por cinco fichas de una oveja. Una vez que el propietario y el destinatario acuerdan
la cuenta, los tokens se colocaban en un sobre de arcilla húmeda. El propietario y el destinatario usaban
sus sellos para sellar el sobre y lo dejaban secar. `}{`[...]`}{` El recibo son tokens selladaos en un
sobre. Cuando el propietario tenía hambre, o quería vender a los hambrientos, o quería semillas para
plantar la próxima primavera, llevaba el sobre al almacén. El reclamante y el operador del almacén
inspeccionarían el sello, lo romperían, inspeccionarían las fichas y luego entregarían los bienes. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Sería bueno si uno pudiera aprender el contenido del sobre, el número y el tipo de
tokens, sin tener que dar el ominoso e irreversible paso de romper el sello. Alrededor
del año 3400 a. C. en Sumer, comenzaron a aparecer marcas en el exterior de estos sobres. Estas marcas
simplemente fueron hechas por las mismos tokens. Las diferentes formas y tamaños de los tokens crearon
impresiones correspondientemente únicas, y por lo tanto los mismos símbolos `}{`[...]`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Con diferentes tokens para una oveja, cinco ovejas, una olla de cebada, cinco ollas de cebada,
etc., obtenemos `}<strong parentName="p">{`m * n`}</strong>{` tokens diferentes `}{`[...]`}{` Al crear fichas separadas para los números
y las mercancías, el número de fichas de diferentes tipos se redujo a `}<strong parentName="p">{`m + n`}</strong>{`, en vez de tener
el doble de tokens por sobre `}{`[...]`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"La evolución de la escritura procedió de allí. Cien años más tarde, se usaban lapices de caña
para imitar las marcas de los tokens de arcilla. A lo largo de los siglos, los escribas complementaron o reemplazaron
los token derivados símbolos con pictografías para los objetos. Las pictografías intentaban representar el
objeto visualmente. Ambos tipos de símbolos se estilizaron como cuñas, o "cuneiformes", optimizados para el lápiz
de caña. Aún más tarde, las palabras representadas ni por pictografías ni por símbolos derivados de tokens`}<br parentName="p"></br>{`
`}{`pasan a estar representados por un acertijo".`}</p>
    </blockquote>
    <p>{`¡Y eso es todo lo que escribió! Bueno, no del todo, pero es una de las historias más creíbles sobre
el desarrollo de la palabra escrita y nos lleva más profundamente al corazón de lo que realmente
estamos innovando en Kernel.`}</p>
    <h3 {...{
      "id": "sellando-el-trato",
      "style": {
        "position": "relative"
      }
    }}>{`Sellando el trato`}<a parentName="h3" {...{
        "href": "#sellando-el-trato",
        "aria-label": "sellando el trato permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Si bien históricamente fascinante, los sellos no fueron el único método para proporcionar evidencia del juego sucio:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Junto con la arcilla a prueba de manipulaciones, los sumerios desarrollaron una especie de evidencia de
manipulación virtual. Tomó la forma de dos conjuntos de números. En el frente de la tablilla, cada grupo de productos se
registraría por separado, por ejemplo, en el frente se registrarían 120 ollas de trigo, 90
ollas de cebada y 55 cabras, en el reverso simplemente se registraría "265": los mismos objetos
contados nuevamente, probablemente en un orden diferente, y sin molestarse en categorizarlos `}{`[... ]`}{`
Las sumas de verificación aún forman la base de la contabilidad moderna. De hecho, el principio de la contabilidad
por partida doble se basa en dos conjuntos de números derivados de forma independiente que deben sumar el mismo número".`}</p>
    </blockquote>
    <p>{`A partir de aquí, Szabo describe los medios modernos para proporcionar evidencia de manipulación y describe el uso
de la criptografía, es decir, la escritura secreta, para proporcionar tanto sumas de control infalsificables
con funciones hash unidireccionales como el desarrollo de firmas digitales. `}</p>
    <p>{`Entonces, ¿qué tiene que ver todo esto con el estado y la historia de Ethereum? Proporciona
la historia de la evolución de nuestra civilización hacia plataformas globales capaces
de garantizar la integridad de los datos a escalas nunca antes posibles, lo que permite el comercio en formas y escalas nunca antes posibles. También prepara el escenario para algunos de los módulos a venir. Bitcoin
y Ethereum no son una aberración de la era digital: son el siguiente bloque en una historia
atemporal sobre la evolución de la confianza y su efecto concomitante sobre lo que significa ser
humano. Como demuestran los antiguos sumerios, en ninguna parte es más evidente el desarrollo continuo
que en nuestra aplicación de las prácticas gemelas de la fe y las finanzas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      